import React, {useEffect} from "react";
import * as styles from "../styles/components/contact-form.module.scss";
import ContentContainer from "./ContentContainer";
import StyledText, {TextStyles} from "./typography";
import LinkText from "./LinkText";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {useActions} from "../store/hooks";
import {ContactFormModel} from "../store/models/contact-form";
import ProgressiveImage from "./common/ProgressiveImage";
import {SocialButtonList} from "./common/button/SocialButtonList";
import {FORM_API_SERVICE_IDS} from "../constants";
import {FormService} from "../store/models/form-service";
import {FormField} from "../store/models/form-field";
import FormSectionTitle from "./form/FormSectionTitle";
import FormSectionField from "./form/FormSectionField";
import Button from "./common/button/Button";
import {SkeletonFormSection} from "./common/SkeletonFormSection";
import {FormStatus} from "../store/types/form-service.types";

export type ContactFormProps = {
    model: ContactFormModel
}

export const ContactForm = (props: ContactFormProps) => {
    const contactFormModel = props.model;
    const loading = useSelector((state: RootState) => state.formService.loading);
    const {fetchFormService, submitFormInstance} = useActions();
    const formService: FormService | null = useSelector((state: RootState) => state.formService.service);
    const status = useSelector((state: RootState) => state.formService.status);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            await fetchFormService(FORM_API_SERVICE_IDS.contact);
        } catch (e) {
            console.error(e)
        }
    }

    return (
        contactFormModel.showForm ? (
                <ContentContainer className={styles.container}>
                    <div className={styles.grid}>
                        <StyledText id='contacts' variant={TextStyles.h2} className={styles.title}>
                            {contactFormModel.title}
                        </StyledText>

                        <SocialButtonList className={styles.contacts} socials={contactFormModel.contacts}/>

                        <div>
                            <LinkText
                                href={`mailto:${contactFormModel.email}`}
                                rel="nofollow"
                                className={styles.email}
                                notInternal
                                target="_blank"
                                textVariant={TextStyles.bodyMenu}
                            >
                                {contactFormModel.email}
                            </LinkText>
                        </div>

                        <div className={styles.formIconContainer}>
                            {contactFormModel.formIcon &&
                              <ProgressiveImage image={contactFormModel.formIcon} className={styles.formIcon}/>}
                        </div>
                    </div>

                    <div></div>
                    <div>
                        {status === FormStatus.FORM_STATUS_SUBMITTED ?
                            <StyledText variant={TextStyles.h2} className={styles.submittedTitle}>
                                {contactFormModel.submittedTitle}
                            </StyledText>
                            :
                            <>
                                {formService && status === FormStatus.FORM_STATUS_LOADED ?
                                    <>
                                        <FormSectionTitle>
                                            {formService?.description}
                                        </FormSectionTitle>
                                        <div className={styles.formGrid}>
                                            {formService?.fields.map(
                                                (field: FormField) => <FormSectionField key={field.uuid}
                                                                                        field={field}/>)
                                            }
                                        </div>
                                    </> :
                                    <SkeletonFormSection sections={1} field={4} classNameGrid={styles.formGrid}/>
                                }
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={submitFormInstance}
                                    disabled={loading}
                                >
                                    <StyledText variant={TextStyles.button}>
                                        {contactFormModel.submitLabel}
                                    </StyledText>
                                </Button>
                            </>
                        }
                    </div>
                </ContentContainer>
            )
            : <></>
    )
}
