import React from "react";
import StyledText, {TextStyles} from "../typography";
import Button from "../common/button/Button";
import {IconLogoStep4} from "../svg";
import ContentContainer from "../ContentContainer";
import * as styles from "../../styles/components/about-us/about-us-info.module.scss";
import {AboutUsPageModel} from "../../store/models/about-us-page";
import LinkText from "../LinkText";

export type AboutUsInfoProps = {
    aboutUsModel: AboutUsPageModel
};

export const AboutUsInfo = (props: AboutUsInfoProps) => {
    const { aboutUsModel } = props;

    return (
        <ContentContainer className={styles.container}>
            <StyledText variant={TextStyles.h1} className={styles.title}>
                {aboutUsModel.title}
            </StyledText>

            <div className={styles.descriptionContainer}>
                <div>
                    <StyledText variant={TextStyles.h5} className={styles.description}>
                        {aboutUsModel.description}
                    </StyledText>
                    <StyledText variant={TextStyles.h5} className={styles.subDescription}>
                        {aboutUsModel.subDescription}
                    </StyledText>

                    <Button variant='outlined' color='secondary' href={aboutUsModel.cta?.url?.link}>
                        <StyledText variant={TextStyles.button}>
                            {aboutUsModel.cta?.label}
                        </StyledText>
                    </Button>
                </div>

                <div className={styles.imgContainer}>
                    <div className={styles.imgGrid}>
                        <IconLogoStep4 isWhite/>
                        <IconLogoStep4 isWhite/>
                        <IconLogoStep4 isWhite/>
                        <IconLogoStep4 isWhite/>
                    </div>
                </div>
            </div>

            <div className={styles.statisticsContainer}>
                {aboutUsModel.statistics && aboutUsModel.statistics.map(item => (
                    <div>
                        <LinkText href={item.url?.link} textVariant={TextStyles.h1} className={styles.statistic}>
                            <StyledText variant={TextStyles.h1} className={styles.total} tag='span'>
                                {item.total}
                            </StyledText>
                            <br />
                            <StyledText variant={TextStyles.h3} className={styles.label} tag='span'>
                                {item.label}
                            </StyledText>
                        </LinkText>
                    </div>
                ))}
            </div>
        </ContentContainer>
    )
}
