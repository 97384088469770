// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var bgImage = "about-us-board-module--bgImage--84d7c";
export var container = "about-us-board-module--container--acfdf";
export var description = "about-us-board-module--description--b998c";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var img = "about-us-board-module--img--5c16e";
export var infoContainer = "about-us-board-module--infoContainer--2b2d7";
export var primaryColor = "#fff";
export var relative = "about-us-board-module--relative--59fb4";
export var secondaryColor = "#000";
export var socials = "about-us-board-module--socials--a5c64";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";