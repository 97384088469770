import * as React from "react"
import {Dispatch, SetStateAction} from "react"
import logo from "../assets/images/Logo_black.svg"
import * as styles from "../styles/components/header.module.scss"
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {MenuModel} from "../store/models/menu";
import ContentContainer, {ContentContainerType} from "./ContentContainer";
import useScrollingUp from "../helpers/use-scrolling-up";
import HeaderLogo from "./header/HeaderLogo";
import HeaderActions, {HeaderActionsColor} from "./header/HeaderActions";

type Props = {
  isWhite?: boolean,
  isScrolled?: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  className?: string,
  menu: MenuModel
}

export const Header = (props: Props) => {
  const {isScrolled, setOpen, className} = props
  const menuModel: MenuModel = props.menu;
  const scrolled = useScrollingUp(150);

  return (
      <div className={`${isScrolled ? styles.isScrolled : ''} ${scrolled ? styles.stickyHeader : ''} ${className ? className : ''}`}>
          <ContentContainer type={ContentContainerType.header}>
              <header className={styles.headerContainer}>
                  <HeaderLogo logo={logo} />
                  <HeaderActions
                      color={HeaderActionsColor.black}
                      ctaLabel={menuModel.cta?.label || ''}
                      ctaLink={menuModel.cta?.url?.link || ''}
                      menuLabel={menuModel.menuBtn || ''}
                      setMenuOpen={setOpen}
                  />
              </header>
          </ContentContainer>
      </div>


  )
}
