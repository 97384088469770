import * as React from "react"
import {Menu} from "./menu/Menu";
import * as styles from "../styles/components/footer.module.scss"
import logoBlack from "../assets/images/Logo_footer.svg";
import * as menuStyles from "../styles/components/menu.module.scss";
import LanguageSelector from "./menu/LanguageSelector";
import ContentContainer, {ContentContainerType} from "./ContentContainer";
import {MenuModel} from "../store/models/menu";

export type FooterProps = {
    menu: MenuModel
}

const Footer = (props: FooterProps) => {
  return (
      <footer className={styles.container}>
          <ContentContainer type={ContentContainerType.menu} className={menuStyles.container}>
              <div className={styles.spacer}></div>
              <Menu isWhite menu={props.menu} />
              <div className={menuStyles.header}>
                  <div className={`${menuStyles.toolbar} ${menuStyles.toolbarColumn}`}>
                      <LanguageSelector isWhite top />
                      <div className={menuStyles.logoContainer}>
                          <div className={menuStyles.logo} >
                              <img src={logoBlack} alt='ITCT_Logo'/>
                          </div>
                      </div>
                      <div></div>
                  </div>
              </div>

          </ContentContainer>
      </footer>
  )
}

export default Footer
