import React from "react";
import ProgressiveImage from "../common/ProgressiveImage";
import StyledText, {TextStyles} from "../typography";
import Button from "../common/button/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {AboutUsPageModel} from "../../store/models/about-us-page";
import * as styles from "../../styles/components/about-us/about-us-img-cta.module.scss"

export type AboutUsImgCtaProps = {
    aboutUsModel: AboutUsPageModel
};

export const AboutUsImgCta = (props: AboutUsImgCtaProps) => {
    const { aboutUsModel } = props;

    return (
        <section className={styles.grid}>
            {aboutUsModel.ctaImg?.image &&
              <ProgressiveImage
                image={aboutUsModel.ctaImg?.image}
                className={styles.img}
              />
            }
            <div className={styles.infoContainer}>
                <StyledText variant={TextStyles.h3}>
                    {aboutUsModel.ctaImg?.title}
                </StyledText>
                <StyledText variant={TextStyles.bodyText} className={styles.description}>
                    {aboutUsModel.ctaImg?.description}
                </StyledText>

                <Button href={aboutUsModel.ctaImg?.cta?.url?.link} color='secondary' variant='contained'>
                    <StyledText variant={TextStyles.button}>
                        {aboutUsModel.ctaImg?.cta?.label}
                    </StyledText>
                </Button>
            </div>
        </section>
    )
}
