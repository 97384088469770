// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var cardContainer = "about-us-module--cardContainer--f2392";
export var cardListGrid = "about-us-module--cardListGrid--c72ec";
export var cardListItem = "about-us-module--cardListItem--1c877";
export var cardTitle = "about-us-module--cardTitle--70672";
export var containerSpacing = "about-us-module--containerSpacing--f4f8b";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";