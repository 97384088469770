import React from "react";
import * as styles from "../../styles/pages/about-us.module.scss";
import ContentContainer from "../ContentContainer";
import StyledText, {TextStyles} from "../typography";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {AboutUsPageModel} from "../../store/models/about-us-page";
import {IconMediator, IconRocket} from "../svg";

export type AboutUsCardProps = {
    aboutUsModel: AboutUsPageModel
};

export const AboutUsCard = (props: AboutUsCardProps) => {
    const { aboutUsModel } = props;

    return (
        <section className={styles.cardContainer}>
            <ContentContainer className={styles.containerSpacing}>
                <StyledText variant={TextStyles.h1} className={styles.cardTitle}>
                    {aboutUsModel.cardTitle}
                </StyledText>

                <div className={styles.cardListGrid}>
                    {aboutUsModel.cardList?.map((item, index) => (
                        <div className={styles.cardListItem}>
                            <div>
                                {index % 2 === 0 ?
                                    <IconRocket/> :
                                    <IconMediator/>
                                }
                            </div>
                            <div>
                                <StyledText variant={TextStyles.h2Caps}>
                                    {item.title}
                                </StyledText>
                                <StyledText variant={TextStyles.bodyText}>
                                    {item.description}
                                </StyledText>
                            </div>

                        </div>
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}
