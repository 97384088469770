import React from "react";
import * as styles from "../../styles/components/about-us/about-us-grids.module.scss";
import StyledText, {TextStyles} from "../typography";
import {IconArrowDown} from "../svg";
import ContentContainer from "../ContentContainer";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {AboutUsPageModel} from "../../store/models/about-us-page";
import LogoContainer from "../LogoContainer";
import Button from "../common/button/Button";

export type AboutUsGridsProps = {
    aboutUsModel: AboutUsPageModel
};

export const AboutUsGrids = (props: AboutUsGridsProps) => {
    const { aboutUsModel } = props;

    return (
        <ContentContainer className={styles.container}>
            <StyledText variant={TextStyles.h2} tag="h2" className={styles.title}>
                {aboutUsModel.grids?.title}
            </StyledText>
            <StyledText variant={TextStyles.bodyMenu} className={styles.subtitle}>
                {aboutUsModel.grids?.subtitle}
            </StyledText>
            <IconArrowDown className={styles.icon}/>

            <div className={styles.gridsContainer}>
                <div>
                    <StyledText variant={TextStyles.h4}>
                        {aboutUsModel.grids?.residentsLabel}
                    </StyledText>
                    <div className={styles.grid}>
                        {aboutUsModel.grids?.residentsGrid?.map((resident) => (
                            <LogoContainer img={resident.resident?.logo}/>
                        ))}
                    </div>
                    <Button href={aboutUsModel.grids?.residentsCta?.url?.link} variant='outlined' color='secondary'>
                        <StyledText variant={TextStyles.button}>
                            {aboutUsModel.grids?.residentsCta?.label}
                        </StyledText>
                    </Button>
                </div>

                <div>
                    <StyledText variant={TextStyles.h4}>
                        {aboutUsModel.grids?.partnersLabel}
                    </StyledText>
                    <div className={styles.grid}>

                        {aboutUsModel.grids?.partnersGrid?.map((partner) => (
                            <LogoContainer img={partner.partner?.logo}/>
                        ))}
                    </div>
                    <Button href={aboutUsModel.grids?.partnersCta?.url?.link} variant='outlined' color='secondary'>
                        <StyledText variant={TextStyles.button}>
                            {aboutUsModel.grids?.partnersCta?.label}
                        </StyledText>
                    </Button>
                </div>
            </div>
        </ContentContainer>
    )
}
