import * as React from "react"
import {ReactNode, useEffect, useState} from "react"
import {Header} from "./Header";
import * as styles from "../styles/layout/layout.module.scss"
import Footer from "./Footer";
import ContentContainer from "./ContentContainer";
import AnimatedBorder from "./AnimatedBorder";
import {MenuDialog} from "./menu/MenuDialog";
import {MenuModel} from "../store/models/menu";

type Props = {
    children: ReactNode,
    slotAboveFooter?: ReactNode,
    noFooter?: boolean,
    noContainer?: boolean,
    className?: string,
    absoluteHeader?: boolean,
    menu: MenuModel,
    location?: any
}

export const Layout = (props: Props) => {
    const {children, slotAboveFooter, noFooter, noContainer, className, absoluteHeader, location} = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(open) {
            setOpen(false)
        }
    }, [location?.hash])

    return (
        <div className={`${styles.container} ${className ? className : ''}`}>
            <AnimatedBorder isOnlyTop={!noFooter}/>
            <Header setOpen={setOpen} className={absoluteHeader ? styles.absoluteHeader : ''} menu={props.menu}/>
            <Header setOpen={setOpen} isScrolled menu={props.menu}/>
            <MenuDialog open={open} setOpen={setOpen} menu={props.menu}/>

            <main>
                {noContainer ?
                    children
                    :
                    <ContentContainer>
                        {children}
                    </ContentContainer>
                }
            </main>
            {slotAboveFooter}
            {noFooter ? <></> : <Footer menu={props.menu}/>}
        </div>
    )
}
