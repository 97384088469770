// extracted by mini-css-extract-plugin
export var container = "about-us-info-module--container--316ad";
export var description = "about-us-info-module--description--f0750";
export var descriptionContainer = "about-us-info-module--descriptionContainer--a682f";
export var imgContainer = "about-us-info-module--imgContainer--c3a09";
export var imgGrid = "about-us-info-module--imgGrid--61217";
export var label = "about-us-info-module--label--b110d";
export var statistic = "about-us-info-module--statistic--29d76";
export var statisticsContainer = "about-us-info-module--statisticsContainer--2574b";
export var subDescription = "about-us-info-module--subDescription--2b5ed";
export var title = "about-us-info-module--title--f4741";
export var total = "about-us-info-module--total--1cddc";