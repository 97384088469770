import React from "react";
import {Layout} from "../components/Layout";
import {AboutUsPageModel} from "../store/models/about-us-page";
import {AboutUsInfo} from "../components/about-us/AboutUsInfo";
import {AboutUsCard} from "../components/about-us/AboutUsCard";
import {AboutUsImgCta} from "../components/about-us/AboutUsImgCta";
import {AboutUsBoard} from "../components/about-us/AboutUsBoard";
import CtaDoubleCard from "../components/cta/CtaDoubleCard";
import {AboutUsGrids} from "../components/about-us/AboutUsGrids";
import {ContactForm} from "../components/ContactForm";
import SEO from "../components/SEO";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {ContactFormModel} from "../store/models/contact-form";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type AboutUsPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse,
        contactForm: StrapiResponse
    },
    location: any
}

const AboutUsPage = (props: AboutUsPageProps) => {
    const aboutUsModel: AboutUsPageModel = AboutUsPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    const contactForm: ContactFormModel = ContactFormModel.fromJSON(props.serverData.contactForm);

    return (
        <Layout noContainer menu={menuModel} location={props?.location}>
            <SEO seo={aboutUsModel.seo}></SEO>

            <AboutUsInfo aboutUsModel={aboutUsModel} />
            <AboutUsCard aboutUsModel={aboutUsModel}/>
            <AboutUsImgCta aboutUsModel={aboutUsModel} />
            <AboutUsBoard aboutUsModel={aboutUsModel}  />
            <CtaDoubleCard ctaCard={aboutUsModel.ctaCard} />
            <AboutUsGrids aboutUsModel={aboutUsModel}  />
            <ContactForm model={contactForm} />
        </Layout>
    )
}

export default AboutUsPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.aboutUsPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);
        const contactForm = await strapi.getSingleType(StrapiSingleTypes.contactForm);

        return {
            props: {
                page,
                menu,
                contactForm
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
