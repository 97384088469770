import React from "react";
import * as styles from "../../styles/components/about-us/about-us-board.module.scss"
import ContentContainer from "../ContentContainer";
import ProgressiveImage from "../common/ProgressiveImage";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {AboutUsPageModel} from "../../store/models/about-us-page";
import StyledText, {TextStyles} from "../typography";
import {SocialButtonList} from "../common/button/SocialButtonList";

export type AboutUsBoardProps = {
    aboutUsModel: AboutUsPageModel
};

export const AboutUsBoard = (props: AboutUsBoardProps) => {
    const { aboutUsModel } = props;

    return (
        <section className={styles.relative}>
            <ContentContainer className={styles.container}>
                {aboutUsModel.boardImage &&
                  <ProgressiveImage
                    image={aboutUsModel.boardImage}
                    className={styles.img}
                  />
                }
                <div className={styles.infoContainer}>
                    <StyledText variant={TextStyles.h1}>
                        {aboutUsModel.boardName}
                    </StyledText>
                    <StyledText variant={TextStyles.h5} className={styles.description}>
                        {aboutUsModel.boardDescription}
                    </StyledText>
                    <SocialButtonList className={styles.socials} socials={aboutUsModel.socials}/>
                </div>
            </ContentContainer>
            <div className={styles.bgImage}></div>
        </section>
    )
}
