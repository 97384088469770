// extracted by mini-css-extract-plugin
export var absoluteHeader = "layout-module--absoluteHeader--31dd6";
export var accentColor = "#e10600";
export var bottomSquares = "layout-module--bottomSquares--0e41e";
export var container = "layout-module--container--3bf91";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var outline = "layout-module--outline--e3898";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var showBorder = "layout-module--showBorder--1576a";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var topSquares = "layout-module--topSquares--bf15f";