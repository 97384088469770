// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var contacts = "contact-form-module--contacts--56242";
export var container = "contact-form-module--container--6ea4a";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var email = "contact-form-module--email--246c7";
export var formGrid = "contact-form-module--formGrid--a1a17";
export var formIcon = "contact-form-module--formIcon--f50c3";
export var formIconContainer = "contact-form-module--formIconContainer--4c9e3";
export var grid = "contact-form-module--grid--e94ee";
export var mobileFormIcon = "contact-form-module--mobileFormIcon--df30c";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var submittedTitle = "contact-form-module--submittedTitle--4ba0a";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "contact-form-module--title--58b98";