import {useEffect, useState} from 'react'

export function on(obj: Window, ...args: any) {
    // @ts-ignore
    obj.addEventListener(...args)
}

export function off(obj: Window, ...args: any) {
    // @ts-ignore
    obj.removeEventListener(...args)
}


const useScrollingUp = (topSpace = 0) => {
    let prevScroll: any = null;
    // @ts-ignore
    // if (process && process.browser) {
    //     prevScroll = window.pageYOffset
    // }
    const [scrollingUp, setScrollingUp] = useState(false)
    const handleScroll = () => {
        const currScroll = window.pageYOffset
        const isScrolled = prevScroll > currScroll && currScroll > topSpace;
        setScrollingUp(isScrolled)
        prevScroll = currScroll
    }
    useEffect(() => {
        on(window, 'scroll', handleScroll, { passive: true })
        return () => {
            off(window, 'scroll', handleScroll, { passive: true })
        }
    }, [])
    return scrollingUp
}

export default useScrollingUp
